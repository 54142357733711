// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VTabBarNew_\\+l5nQ{display:flex;position:relative;-webkit-user-select:none;user-select:none}.VTabBarNew_\\+l5nQ._small_7bp1r{padding:0}.VTabBarNew_\\+l5nQ._medium_dSq8V{padding:.4rem}.VTabBarNew_\\+l5nQ._medium_dSq8V .tab_0DKTx._separator_O8YC0:before{height:1.2rem}.VTabBarNew_\\+l5nQ._base_UPHMu,.VTabBarNew_\\+l5nQ._colorful_zgWk4{background-color:#f1f1f4}.VTabBarNew_\\+l5nQ._white_hswwj,.VTabBarNew_\\+l5nQ._white-base-100_yzJw1{background-color:#fff}.VTabBarNew_\\+l5nQ._dark_GHVPC{background-color:#ffffff1a}.VTabBarNew_\\+l5nQ._dark_GHVPC .tab_0DKTx._separator_O8YC0:before{border-left:1px solid #fff3}.VTabBarNew_\\+l5nQ._monochrome_nr9UJ{background-color:#f1f1f4}.VTabBarNew_\\+l5nQ._transparent_RE3qy{background-color:#0000;gap:.6rem}.VTabBarNew_\\+l5nQ._transparent_RE3qy .tab_0DKTx._separator_O8YC0:before{display:none}.VTabBarNew_\\+l5nQ._opened_U40OF{background-color:#0000}.VTabBarNew_\\+l5nQ._stretch_TgpJl .tab_0DKTx{flex:1;justify-content:center}.VTabBarNew_\\+l5nQ._rounded_fICOe{border-radius:1rem}.tab_0DKTx._separator_O8YC0{position:relative}.tab_0DKTx._separator_O8YC0:before{border-left:1px solid #e3e5e9;content:\"\";left:0;position:absolute;top:50%;transform:translateY(-50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"VTabBarNew": "VTabBarNew_+l5nQ",
	"_small": "_small_7bp1r",
	"_medium": "_medium_dSq8V",
	"tab": "tab_0DKTx",
	"_separator": "_separator_O8YC0",
	"_base": "_base_UPHMu",
	"_colorful": "_colorful_zgWk4",
	"_white": "_white_hswwj",
	"_white-base-100": "_white-base-100_yzJw1",
	"_dark": "_dark_GHVPC",
	"_monochrome": "_monochrome_nr9UJ",
	"_transparent": "_transparent_RE3qy",
	"_opened": "_opened_U40OF",
	"_stretch": "_stretch_TgpJl",
	"_rounded": "_rounded_fICOe"
};
module.exports = ___CSS_LOADER_EXPORT___;
