// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VTooltipContent_zxpJc{align-items:center;display:flex;justify-content:center;position:relative;white-space:nowrap}.VTooltipContent_zxpJc._medium_ZY34g{border-radius:.4rem;font-size:1.4rem;font-weight:500;height:3.2rem;letter-spacing:-.04em;line-height:120%;padding:0 1.2rem}.VTooltipContent_zxpJc._base_myM4E{background-color:#73788ccc;color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"VTooltipContent": "VTooltipContent_zxpJc",
	"_medium": "_medium_ZY34g",
	"_base": "_base_myM4E"
};
module.exports = ___CSS_LOADER_EXPORT___;
